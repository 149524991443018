import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`v1.0`}</p>
    </blockquote>
    <h2>{`1. Introduction`}</h2>
    <p>{`CoreMedia Lead Activation SA is committed to protecting the privacy and personal data of our users and customers. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data in compliance with the General Data Protection Regulation (GDPR) and other applicable data protection laws.`}</p>
    <h3>{`1.1. What is a RGPD policy?`}</h3>
    <h3>{`1.2. What is a data processor or a ?`}</h3>
    <h2>{`2. Information We Collect`}</h2>
    <p>{`CoreMedia can collect the following types of personal data for the purposes of providing our digital marketing services:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Contact Information:`}</strong>{` This may include your name, email address, phone number, and postal address.`}</li>
      <li parentName="ul"><strong parentName="li">{`Account Information:`}</strong>{` Usernames, passwords, and other login credentials.`}</li>
      <li parentName="ul"><strong parentName="li">{`Usage Data:`}</strong>{` Information about how you use our digital marketing services, including device and browser information.`}</li>
      <li parentName="ul"><strong parentName="li">{`Payment Data:`}</strong>{` Data necessary for processing payments, such as credit card information for service subscriptions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Communication Data:`}</strong>{` Information related to your communications with us, including emails, support inquiries, and chat transcripts.`}</li>
    </ul>
    <h2>{`3. How We Use Your Data`}</h2>
    <p>{`We use your personal data for the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`To provide and maintain our digital marketing services.`}</li>
      <li parentName="ul">{`To communicate with you, respond to your inquiries, and provide customer support.`}</li>
      <li parentName="ul">{`To improve and customize our services and deliver personalized content.`}</li>
      <li parentName="ul">{`To comply with legal obligations and regulatory requirements.`}</li>
    </ul>
    <h2>{`4. Sharing Your Data`}</h2>
    <p>{`We may share your personal data with trusted service providers, business partners, or authorities when necessary to fulfill the purposes described in this Privacy Policy.`}</p>
    <h2>{`5. Your Rights`}</h2>
    <p>{`You have the following rights concerning your personal data:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Right to Access:`}</strong>{` You have the right to request access to your personal data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Right to Rectify:`}</strong>{` You can request corrections to inaccuracies in your data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Right to Erasure:`}</strong>{` You have the right to request the deletion of your data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Right to Data Portability:`}</strong>{` You can request a copy of your data in a structured, machine-readable format.`}</li>
      <li parentName="ul"><strong parentName="li">{`Right to Object:`}</strong>{` You can object to the processing of your data under certain circumstances.`}</li>
    </ul>
    <h2>{`6. Data Security`}</h2>
    <p>{`We take data security seriously and implement appropriate measures to protect your data. Our security practices adhere to industry standards to safeguard your information.`}</p>
    <h2>{`7. Contact Us`}</h2>
    <p>{`If you have questions about this Privacy Policy, your personal data, or would like to exercise your rights, please contact us at `}{`[Insert Contact Information]`}{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      